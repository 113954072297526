import { actionTypes } from './actions';

const initialState = {
  user: null,
  getUserRequest: false,
  getUserError: null,
};

const handleGetCurrentUserRequest = state => ({
  ...state,
  getUserRequest: true,
});

const handleGetCurrentUserSuccess = (state, action) => ({
  ...state,
  user: {
    ...action.data.user,
    _hasAccessRights: !!action.data.user.accessRights?.length
  },
  getUserRequest: false,
});

const handleGetCurrentUserError = (state, action) => ({
  ...state,
  getUserRequest: false,
  getUserError: action.error,
});

const handleSetUserData = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    ...action.data.user
  }
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_CURRENT_USER_REQUEST]: handleGetCurrentUserRequest,
    [actionTypes.GET_CURRENT_USER_SUCCESS]: handleGetCurrentUserSuccess,
    [actionTypes.GET_CURRENT_USER_ERROR]: handleGetCurrentUserError,
    [actionTypes.SET_USER_DATA]: handleSetUserData,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
