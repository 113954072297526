import { actionTypes } from './actions';

const initialState = {
  docsProjectsCategory: null,

  getDocsProjectsCategoryRequest: false,
  getDocsProjectsCategorySuccess: false,
  getDocsProjectsCategoryError: false,
};

const handleGetDocsProjectsCategoryRequest = (state) => ({
  ...state,
  getDocsProjectsCategoryRequest: true,
  getDocsProjectsCategorySuccess: false,
  getDocsProjectsCategoryError: false,
});

const handleGetDocsProjectsCategorySuccess = (state, action) => ({
  ...state,
  getDocsProjectsCategoryRequest: false,
  getDocsProjectsCategorySuccess: true,
  getDocsProjectsCategoryError: false,
  docsProjectsCategory: action.data,
});

const handleGetDocsProjectsCategoryError = (state) => ({
  ...state,
  getDocsProjectsCategoryRequest: false,
  getDocsProjectsCategorySuccess: false,
  getDocsProjectsCategoryError: true,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_DOCS_PROJECTS_CATEGORY]: handleResetState,

    [actionTypes.GET_DOCS_PROJECTS_CATEGORY_REQUEST]: handleGetDocsProjectsCategoryRequest,
    [actionTypes.GET_DOCS_PROJECTS_CATEGORY_SUCCESS]: handleGetDocsProjectsCategorySuccess,
    [actionTypes.GET_DOCS_PROJECTS_CATEGORY_ERROR]: handleGetDocsProjectsCategoryError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
