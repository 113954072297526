import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useRouter } from "next/router";

import { getCurrentUser } from '../../../services/user/actions';
import urls from '../../../common/urls';

import SideNav from '../SideNav';
import Footer from '../Footer';

import S from './styles';

const PrivateLayout = (props) => {
  const {
    user,
    getCurrentUserAction,
    children
  } = props;
  const [isAllowedToRender, setIsAllowedToRender] = useState(false);

  const router = useRouter();

  useEffect(() => {
    getCurrentUserAction();
  }, []);

  useEffect(() => {
    if (user) {
      if (user._hasAccessRights) {
        // Users with access rights can only view routes start with /docs
        if (router.pathname.indexOf('/docs') === 0) {
          setIsAllowedToRender(true);  
        } else {
          window.location.replace(urls.docs.list);
        }
      } else {
        setIsAllowedToRender(true);  
      }
    }
  }, [user]);

  return (
    <S.Container>
      <S.Wrapper>
        {isAllowedToRender && <>
          <SideNav />

          <S.Content>
            {children}
          </S.Content>

          <Footer />
        </>}
      </S.Wrapper>
    </S.Container>
  )
};

PrivateLayout.propTypes = {
  children: PropTypes.node,
};

PrivateLayout.defaultProps = {
  children: null,
};

const mapStateToProps = ({
  userService,
}) => {
  const { user } = userService;

  return {
    user,
  };
};

const mapDispatchToProps = {
  getCurrentUserAction: getCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout);
