export const actionTypes = {
  GET_DOCS_PROJECTS_CATEGORIES: 'GET_DOCS_PROJECTS_CATEGORIES',
  GET_DOCS_PROJECTS_CATEGORIES_REQUEST: 'GET_DOCS_PROJECTS_CATEGORIES_REQUEST',
  GET_DOCS_PROJECTS_CATEGORIES_SUCCESS: 'GET_DOCS_PROJECTS_CATEGORIES_SUCCESS',
  GET_DOCS_PROJECTS_CATEGORIES_ERROR: 'GET_DOCS_PROJECTS_CATEGORIES_ERROR',

  RESET_STATE_DOCS_PROJECTS_CATEGORIES: 'RESET_STATE_DOCS_PROJECTS_CATEGORIES',
};

export const resetStateDocsProjectsCategories = () => ({ type: actionTypes.RESET_STATE_DOCS_PROJECTS_CATEGORIES });

export const getDocsProjectsCategories = () => ({ type: actionTypes.GET_DOCS_PROJECTS_CATEGORIES });

export const getDocsProjectsCategoriesRequest = () => ({ type: actionTypes.GET_DOCS_PROJECTS_CATEGORIES_REQUEST });

export const getDocsProjectsCategoriesSuccess = (data) => ({ type: actionTypes.GET_DOCS_PROJECTS_CATEGORIES_SUCCESS, data });

export const getDocsProjectsCategoriesError = (error) => ({ type: actionTypes.GET_DOCS_PROJECTS_CATEGORIES_ERROR, error });
