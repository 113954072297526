import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiCreateDoc = (data) => axios({
  method: 'POST',
  url: apiUrls.clientApi.docs.create,
  data,
});

export const apiUpdateDoc = (data) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.docs.update,
  data,
});

export const apiGetDoc = (id, params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docs.item(id),
  params,
});

export const apiDeleteDoc = (id) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.docs.delete,
  params: {
    id,
  },
});

export const apiResetDocReads = (docId) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.docs.resetReads,
  params: {
    docId,
  },
});

export const apiCreateDocChangelog = (data) => axios({
  method: 'POST',
  url: apiUrls.clientApi.docsChangelogs.create,
  data,
});

export const apiDetachDocsCommonProcess = (docsCommonProcessId) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.docs.detachDocsCommonProcess,
  params: {
    docsCommonProcessId,
  },
});

export const apiAttachDocsCommonProcess = (data) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.docs.attachDocsCommonProcess,
  data,
});

export const apiUpdateDocsCommonProcess = (data) => {
  axios({
    method: 'PUT',
    url: apiUrls.clientApi.docsCommonProcesses.update,
    data,
  });
};
