import { actionTypes } from './actions';

const initialState = {
  usersAccessRightsTypes: null,
  updateUserRequest: false
};


const handleGetUsersAccessRightsTypesSuccess = (state, action) => ({
  ...state,
  usersAccessRightsTypes: action.data
});

const handleUpdateUserRequest = (state, action) => ({
  ...state,
  updateUserRequest: true
});

const handleUpdateUserSuccess = (state, action) => ({
  ...state,
  updateUserRequest: false
});

const handleUpdateUserError = (state, action) => ({
  ...state,
  updateUserRequest: false
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.UPDATE_USER_REQUEST_USER_EDITOR]: handleUpdateUserRequest,
    [actionTypes.UPDATE_USER_SUCCESS_USER_EDITOR]: handleUpdateUserSuccess,
    [actionTypes.UPDATE_USER_ERROR_USER_EDITOR]: handleUpdateUserError,
    [actionTypes.GET_USERS_ACCESS_RIGHTS_TYPES_SUCCESS_USER_EDITOR]: handleGetUsersAccessRightsTypesSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
