import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetDocsProjectsCategory,
} from './api';

import {
  actionTypes,

  getDocsProjectsCategoryRequest,
  getDocsProjectsCategorySuccess,
  getDocsProjectsCategoryError,
} from './actions';


function* getDocsProjectsCategorySaga({ id }) {
  try {
    yield put(getDocsProjectsCategoryRequest());

    const { data: { data: docsProjectsCategory } } = yield call(() => apiGetDocsProjectsCategory(id, {
      docsProjectsCategory,
    }));

    yield put(getDocsProjectsCategorySuccess(docsProjectsCategory));
  } catch (error) {
    console.log('GET DOCS ROJECTS CATEGORY ERROR', error);
    yield put(getDocsProjectsCategoryError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_DOCS_PROJECTS_CATEGORY, getDocsProjectsCategorySaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
