import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetDoc,
} from './api';

import {
  actionTypes,
  getDocRequest,
  getDocSuccess,
  getDocError,
} from './actions';

function* getDocSaga({ id, isProject }) {
  try {
    const { user } = yield select(({ userService }) => userService);

    yield put(getDocRequest());

    const { data: { data: doc } } = yield call(() => apiGetDoc(id, {
      currentUserGlobalId: user.globalId,
      isProject
    }));

    yield put(getDocSuccess(doc));
  } catch (error) {
    console.log('GET DOC ERROR', error);
    yield put(getDocError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_DOC, getDocSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
