export const actionTypes = {
  UPDATE_USER_USER_EDITOR: 'UPDATE_USER_USER_EDITOR',
  UPDATE_USER_REQUEST_USER_EDITOR: 'UPDATE_USER_REQUEST_USER_EDITOR',
  UPDATE_USER_SUCCESS_USER_EDITOR: 'UPDATE_USER_SUCCESS_USER_EDITOR',
  UPDATE_USER_ERROR_USER_EDITOR: 'UPDATE_USER_ERROR_USER_EDITOR',

  GET_USERS_ACCESS_RIGHTS_TYPES_USER_EDITOR: 'GET_USERS_ACCESS_RIGHTS_TYPES_USER_EDITOR',
  GET_USERS_ACCESS_RIGHTS_TYPES_SUCCESS_USER_EDITOR: 'GET_USERS_ACCESS_RIGHTS_TYPES_SUCCESS_USER_EDITOR',
};

export const updateUser = (
  user,
) => ({
  type: actionTypes.UPDATE_USER_USER_EDITOR,
  user
});

export const getUsersAccessRightsTypes = () => ({
  type: actionTypes.GET_USERS_ACCESS_RIGHTS_TYPES_USER_EDITOR,
});

export const updateUserRequest = () => ({
  type: actionTypes.UPDATE_USER_REQUEST_USER_EDITOR,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS_USER_EDITOR,
});

export const updateUserError = () => ({
  type: actionTypes.UPDATE_USER_ERROR_USER_EDITOR,
});

export const getUsersAccessRightsTypesSuccess = (data) => ({
  type: actionTypes.GET_USERS_ACCESS_RIGHTS_TYPES_SUCCESS_USER_EDITOR,
  data,
});
