export const actionTypes = {
  GET_DOCS_PROJECTS_CATEGORY: 'GET_DOCS_PROJECTS_CATEGORY',
  GET_DOCS_PROJECTS_CATEGORY_REQUEST: 'GET_DOCS_PROJECTS_CATEGORY_REQUEST',
  GET_DOCS_PROJECTS_CATEGORY_SUCCESS: 'GET_DOCS_PROJECTS_CATEGORY_SUCCESS',
  GET_DOCS_PROJECTS_CATEGORY_ERROR: 'GET_DOCS_PROJECTS_CATEGORY_ERROR',

  RESET_STATE_DOCS_PROJECTS_CATEGORY: 'RESET_STATE_DOCS_PROJECTS_CATEGORY',
};

export const resetStateDocsProjectsCategory = () => ({ type: actionTypes.RESET_STATE_DOCS_PROJECTS_CATEGORY });


export const getDocsProjectsCategory = (id) => ({ type: actionTypes.GET_DOCS_PROJECTS_CATEGORY, id });

export const getDocsProjectsCategoryRequest = () => ({ type: actionTypes.GET_DOCS_PROJECTS_CATEGORY_REQUEST });

export const getDocsProjectsCategorySuccess = (data) => ({ type: actionTypes.GET_DOCS_PROJECTS_CATEGORY_SUCCESS, data });

export const getDocsProjectsCategoryError = (error) => ({ type: actionTypes.GET_DOCS_PROJECTS_CATEGORY_ERROR, error });
