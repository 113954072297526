export const actionTypes = {
  GET_DOC: 'GET_DOC_PREVIEW',
  GET_DOC_REQUEST: 'GET_DOC_PREVIEW_REQUEST',
  GET_DOC_SUCCESS: 'GET_DOC_PREVIEW_SUCCESS',
  GET_DOC_ERROR: 'GET_DOC_PREVIEW_ERROR',

  RESET_STATE_DOC: 'RESET_STATE_DOC',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_DOC });

export const getDoc = (id, isProject) => ({ type: actionTypes.GET_DOC, id, isProject });

export const getDocRequest = () => ({ type: actionTypes.GET_DOC_REQUEST });

export const getDocSuccess = (data) => ({ type: actionTypes.GET_DOC_SUCCESS, data });

export const getDocError = (error) => ({ type: actionTypes.GET_DOC_ERROR, error });

