import { actionTypes } from './actions';

const initialState = {
  docsProjectsCategories: [],
  getDocsProjectsCategoriesRequest: false,
  getDocsProjectsCategoriesSuccess: false,
  getDocsProjectsCategoriesError: false,
};

const handleGetDocsProjectsCategoriesRequest = (state) => ({
  ...state,
  getDocsProjectsCategoriesRequest: true,
  getDocsProjectsCategoriesSuccess: false,
  getDocsProjectsCategoriesError: false,
});

const handleGetDocsProjectsCategoriesSuccess = (state, action) => ({
  ...state,
  getDocsProjectsCategoriesRequest: false,
  getDocsProjectsCategoriesSuccess: true,
  getDocsProjectsCategoriesError: false,
  docsProjectsCategories: action.data,
});

const handleGetDocsProjectsCategoriesError = (state) => ({
  ...state,
  getDocsProjectsCategoriesRequest: false,
  getDocsProjectsCategoriesSuccess: false,
  getDocsProjectsCategoriesError: true,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_DOCS_PROJECTS_CATEGORIES]: handleResetState,

    [actionTypes.GET_DOCS_PROJECTS_CATEGORIES_REQUEST]: handleGetDocsProjectsCategoriesRequest,
    [actionTypes.GET_DOCS_PROJECTS_CATEGORIES_SUCCESS]: handleGetDocsProjectsCategoriesSuccess,
    [actionTypes.GET_DOCS_PROJECTS_CATEGORIES_ERROR]: handleGetDocsProjectsCategoriesError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
