import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetUsersAccessRightsTypes = (data) => axios({
  method: 'GET',
  url: apiUrls.clientApi.usersAccessRightsTypes.list,
});

export const apiUpdateUser = (data) => {
  axios({
    method: 'PUT',
    url: apiUrls.clientApi.users.update,
    data,
  });
};
