import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetDocsProjectsCategories,
} from './api';

import {
  actionTypes,

  getDocsProjectsCategoriesRequest,
  getDocsProjectsCategoriesSuccess,
  getDocsProjectsCategoriesError,
} from './actions';


function* getDocsProjectsCategoriesSaga(data) {
  try {
    yield put(getDocsProjectsCategoriesRequest());

    const { data: { data: docsCategories } } = yield call(() => apiGetDocsProjectsCategories());

    yield put(getDocsProjectsCategoriesSuccess(docsCategories));
  } catch (error) {
    console.log('GET DOCS PROJECTS CATEGORIES ERROR', error);
    yield put(getDocsProjectsCategoriesError(error));
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_DOCS_PROJECTS_CATEGORIES, getDocsProjectsCategoriesSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
