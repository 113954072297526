import { takeEvery, all, call, put } from "redux-saga/effects";
import { store } from "react-notifications-component";

import { 
  apiGetUsersAccessRightsTypes,
  apiUpdateUser 
} from './api';

import {
  actionTypes,
  getUsersAccessRightsTypesSuccess,
  updateUserRequest,
  updateUserSuccess,
  updateUserError
} from "./actions";

function* updateUserSaga(action) {
  try {
    console.log('updateUserSaga');

    const userData = action.user;

    console.log('userData', userData);

    yield put(updateUserRequest());

    const data = action.user;

    yield call(() => apiUpdateUser(userData));

    yield put(updateUserSuccess());

    store.addNotification({
      type: 'success',
      message: 'Юзер сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: {
        // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });

    window.location.reload();
  } catch (error) {
    console.log('UPDATE USER ERROR', error);

    yield put(updateUserError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Юзер не сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: {
        // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* getUsersAccessRightsTypesSaga() {
  try {
    const {
      data: { data: usersAccessRightsTypes },
    } = yield call(() => apiGetUsersAccessRightsTypes());

    yield put(getUsersAccessRightsTypesSuccess(usersAccessRightsTypes));
  } catch (error) {
    console.log('GET USERS ACCESS RIGHTS TYPES ERROR', error);
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.UPDATE_USER_USER_EDITOR, updateUserSaga);
  yield takeEvery(actionTypes.GET_USERS_ACCESS_RIGHTS_TYPES_USER_EDITOR, getUsersAccessRightsTypesSaga);
}

export default function* root() {
  yield all([watchSagas()]);
}
